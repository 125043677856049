import {
    hidden,
    newInput,
    tryToDate,
    visible,
    getDataByCodePostal,
    getDataBySiren,
    cssVal,
    isUndef, isEmpty, texteForCivilite, texteForAdmissionAssemble, texteForCessionTransmissionAction
} from "@/data/helpers";
import {def, mask} from "@/data/dictionnaire";


export const transformationSarlEnSasOuSasu = {
    title: "Transformation de SARL en SAS ou SASU",
    texte: "<strong>{{societeGroup.societeDenomination}}</strong>[[ ({{societeGroup.sigle}})]] {{societeGroup.societeFormeJuridique}}, au capital de {{societeGroup.societeCapital}} €, Siège : {{siegeSocialGroup.societeAdresse}}[[, " +
        "{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}, RCS : <span style='text-transform: uppercase'>{{societeGroup.societeRcsVille}}</span> N° {{societeGroup.societeRcs}}. <br>" +
        "Suivant AGE en date du {{acteGroup.dateAGE}}, il a été décidé la transformation de la société en {{mentionGroup.forme}}, sans création d'un être moral nouveau, à compter du {{acteGroup.aCompterDu}}. "+
        "Mentions modifiées ou ajoutées : [[Dénomination : {{mentionGroup.denomination}}]] Forme : {{mentionGroup.forme}} Capital : {{societeGroup.montantCapital}} € divisé en {{mentionGroup.diviseEn}} actions de {{mentionGroup.montantAction}} € chacune. "+
        "Président : {{mentionGroup.civilitePresident}} {{mentionGroup.prenomPresident}} {{mentionGroup.nomPresident}} {{mentionGroup.adressePresident}}[[ {{mentionGroup.adresseSuitePresident}}]] {{mentionGroup.codePostalPresident}} {{mentionGroup.villePresident}} est nommé président {{remplacement}}." +
        "[[Directeur général : {{mentionGroup.civiliteDirecteurGeneral}} {{mentionGroup.prenomDirecteurGeneral}} {{mentionGroup.nomDirecteurGeneral}} {{mentionGroup.adresseDirecteurGeneral}}[[ {{mentionGroup.adresseSuiteDirecteurGeneral}}]] {{mentionGroup.codePostalDirecteurGeneral}} {{mentionGroup.villeDirecteurGeneral}} est nommé Directeur général. ]]"+
        "[[{{mentionGroup.commissaireAuxComptes}} [[{{mentionGroup.suppleant}}]] ]]. Admission aux assemblées : {{admissionAssembleGroup}}. Cession et transmission des actions : {{cessionTransmissionActionGroup}}.",
        script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "acteGroup.dateAGE":
            case "acteGroup.aCompterDu":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            case "remplacement":
                if(varText['mentionGroup.nouveauPresident'] === 'oui') {
                    tmpText = ', en remplacement de '+ varText['mentionGroup.enRemplacementDe'];
                }
                break;
            case "mentionGroup.commissaireAuxComptes":
                if(!isUndef(varText['mentionGroup.typePersonneCommissaireCompte']) && varText['mentionGroup.typePersonneCommissaireCompte'] === 'morale') {
                    if(isEmpty(varText["mentionGroup.rcsCommissaireCompte"]) && isEmpty(varText["mentionGroup.denominationCommissaireCompte"]) && isEmpty(varText["mentionGroup.lieuImmatriculationCommissaireCompte"]))
                        tmpText = '';

                    tmpText = ` Commissaires aux comptes titulaire : ${cssVal(varText["mentionGroup.denominationCommissaireCompte"]) || ''} ${isEmpty(varText["mentionGroup.sigleCommissaireCompte"]) ? '' : '('+ cssVal(varText["mentionGroup.sigleCommissaireCompte"]) +')' } <span class='user-value'>${varText["mentionGroup.formesSocietesCommissaireCompte"] || ''}</span> Capital : ${cssVal(varText["mentionGroup.montantCapitalCommissaireCompte"])} €, <span class='user-value'>${varText["mentionGroup.adresseCommissaireCompte"] || ''} ${varText["mentionGroup.adresseSuiteCommissaireCompte"] || ``} ${varText["mentionGroup.codePostalCommissaireCompte"] || ''} ${varText["mentionGroup.villeCommissaireCompte"] || ''}</span>, Rcs <span class='user-value'>${varText["mentionGroup.lieuImmatriculationCommissaireCompte"] || ''} ${varText["mentionGroup.rcsCommissaireCompte"] || ''}</span>.`
                } else {
                    if(isEmpty(varText["mentionGroup.civiliteCommissaireCompte"]) && isEmpty(varText["mentionGroup.nomCommissaireCompte"]) && isEmpty(varText["mentionGroup.prenomCommissaireCompte"]))
                        tmpText = '';

                    tmpText = ` Commissaires aux comptes titulaire : <span class='user-value'>${texteForCivilite(varText["mentionGroup.civiliteCommissaireCompte"])} ${varText["mentionGroup.prenomCommissaireCompte"] || ''} ${varText["mentionGroup.nomCommissaireCompte"] || ''}, ${varText["mentionGroup.adresseCommissaireCompte"] || ''} ${varText["mentionGroup.adresseSuiteCommissaireCompte"] || ``} ${varText["mentionGroup.codePostalCommissaireCompte"] || ''} ${varText["mentionGroup.villeCommissaireCompte"] || ''} </span>`;
                }
                break;
            case "mentionGroup.suppleant":
                if(!isUndef(varText['mentionGroup.typeSuppleant']) && varText['mentionGroup.typeSuppleant'] === 'morale') {
                    if(isEmpty(varText["mentionGroup.rcsSuppleant"]) && isEmpty(varText["mentionGroup.denominationSuppleant"]) && isEmpty(varText["mentionGroup.lieuImmatriculationSuppleant"]))
                        tmpText = '';

                    tmpText = ` Suppléant : ${cssVal(varText["mentionGroup.denominationSuppleant"])} ${isEmpty(varText["mentionGroup.sigleSuppleant"]) ? '' : '('+ cssVal(varText["mentionGroup.sigleSuppleant"]) +')' } <span class='user-value'>${varText["mentionGroup.formesSocietesSuppleant"] || ''}</span> Capital : ${cssVal(varText["mentionGroup.montantCapitalSuppleant"])} €, <span class='user-value'>${varText["mentionGroup.adresseSuppleant"] || ''} ${varText["mentionGroup.adresseSuiteSuppleant"] || ``} ${varText["mentionGroup.codePostalSuppleant"] || ''} ${varText["mentionGroup.villeSuppleant"] || ''}</span>, Rcs <span class='user-value'>${varText["mentionGroup.lieuImmatriculationSuppleant"] || ''} ${varText["mentionGroup.rcsSuppleant"] || ''}</span>.`
                } else if(varText['mentionGroup.typeSuppleant'] === 'physique') {
                    if(isEmpty(varText["mentionGroup.civiliteSuppleant"]) && isEmpty(varText["mentionGroup.nomSuppleant"]) && isEmpty(varText["mentionGroup.prenomSuppleant"]))
                        tmpText = '';

                    tmpText = ` Suppléant : <span class='user-value'>${texteForCivilite(varText["mentionGroup.civiliteSuppleant"])} ${varText["mentionGroup.prenomSuppleant"] || ''} ${varText["mentionGroup.nomSuppleant"] || ''}, ${varText["mentionGroup.adresseSuppleant"] || ''} ${varText["mentionGroup.adresseSuiteSuppleant"] || ``} ${varText["mentionGroup.codePostalSuppleant"] || ''} ${varText["mentionGroup.villeSuppleant"] || ''} </span>`;
                } else {
                    tmpText = '';
                }
                break;
            case "admissionAssembleGroup":
                tmpText = texteForAdmissionAssemble(varText, 'mentionGroup');
                break;
            case "cessionTransmissionActionGroup":
                tmpText = texteForCessionTransmissionAction(varText, 'mentionGroup');
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        societeGroup: [{}],
        siegeSocialGroup: [{}],
        mentionGroup: [{}],
    },
    schema: [
        {
            //0
            name: "societeGroup",
            label: "Société",
            type: "group",
            validation: "required",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if (!data.result) {
                                return false;
                            }

                            transformationSarlEnSasOuSasu.model.societeGroup[0] = data.values.societe;
                            transformationSarlEnSasOuSasu.model.siegeSocialGroup[0] = data.values.siegeSocial;

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.ville);
                            transformationSarlEnSasOuSasu.schema[1].children[3].options = dataCP.options;
                            transformationSarlEnSasOuSasu.model.siegeSocialGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.montantCapital, '_required', {name: 'societeCapital',
                    on: {
                        input: (val) => {
                            transformationSarlEnSasOuSasu.schema[3].value[0].capital = val;
                            transformationSarlEnSasOuSasu.schema[3].children[6].label = 'Capital '+ val + ' Euros';
                        }
                    }}),
            ],
        },
        {
            //1
            name: "siegeSocialGroup",
            label: "Siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);
                                transformationSarlEnSasOuSasu.schema[1].children[3].options = data.options;

                                if (data.ville_slug !== null) {
                                    transformationSarlEnSasOuSasu.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                                    transformationSarlEnSasOuSasu.model.societeGroup[0].societeRcsVille = data.greffe;
                                }
                            }
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //2
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.dateAGE, '_required'),
                newInput(def.aCompterDu, '_required'),
            ]
        },
        {
            //3
            name: "mentionGroup",
            label: "Mentions modifiées ou ajoutées",
            value: [{}],
            type: "group",
            children: [
                {label: 'Dénomination', type: 'title', "label-class": 'subtitle'},
                newInput({ name: "typedenomination", label: "Dénomination", type: "radio",
                        options: [
                            {value: 'oui', label: 'Oui'},
                            {value: 'non', label: 'Non'}
                        ],
                     }, '_required', {
                    on: {
                        input: (val) => {
                            if(val === 'oui') {
                                transformationSarlEnSasOuSasu.schema[3].children[2] = visible(transformationSarlEnSasOuSasu.schema[3].children[2]);
                            } else {
                                transformationSarlEnSasOuSasu.schema[3].children[2] = hidden(transformationSarlEnSasOuSasu.schema[3].children[2]);
                            }
                        }
                    }
                }),
                newInput(def.denomination, '_required', {}, 'hidden'),


                {label: 'Forme', type: 'title', "label-class": 'subtitle'},
                newInput({ name: "forme", label: "Forme", type: "select",
                    options: [
                        {value: '', label: 'Choisir'},
                        {value: 'SAS', label: 'SAS'},
                        {value: 'SAU', label: 'SAU'}
                    ],
                }, '_required'),


                {label: 'Capital', type: 'title', "label-class": 'subtitle'},
                newInput({name: "capital", label: "Capital", type: "text"}, '', {}, 'disabled'),
                newInput({name: "diviseEn", label: "divisé en", type: "text" }, '_required'),
                newInput(def.montantAction, '_required'),


                {label: 'Président', type: 'title', "label-class": 'subtitle'},
                newInput({name: 'nouveauPresident', label: 'Nouveau président', type: 'radio',
                    options: [
                        {value: "oui", label: "Oui"},
                        {value: "non", label: "Non"},
                    ]}, '_required', {
                    on: {
                        input: (val) => {
                            transformationSarlEnSasOuSasu.schema[3].children[18] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[18]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[18]);
                        }
                    }
                    }),
                newInput({
                    name: "civilitePresident",
                    label: "Civilité",
                    type: "select",
                    options: [
                        {value: "", label: "Choisissez"},
                        {value: "homme", label: "Homme"},
                        {value: "femme", label: "Femme"},],
                }, '_required'),
                newInput({name: "nomPresident", label: "Nom", type: "text"}, '_required'),
                newInput({name: "prenomPresident", label: "Prénom", type: "text"}, '_required'),
                newInput({name: "adressePresident", label: "Adresse", type: "text"}, '_required'),
                newInput({name: "adresseSuitePresident", label: "Adresse Suite", type: "text"}, '_required'),
                newInput({name: "codePostalPresident", label: "Code postal", type: "text"}, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);
                                transformationSarlEnSasOuSasu.schema[3].children[17].options = data.options;

                                if (data.ville_slug !== null) {
                                    transformationSarlEnSasOuSasu.model.mentionGroup[0].villePresident = data.ville_slug;
                                }
                            }
                        }
                    }
                }),
                newInput({
                    name: "villePresident",
                    label: "ville",
                    type: "select",
                    options: [{value: "", label: "Choisissez"}, {value: "neuilly sur marne", label: "Neuilly"},],
                }, '_required'),
                newInput(def.enRemplacementDe, '_required', {}, 'hidden'),
                //18

                {label: 'Directeur général', type: 'title', "label-class": 'subtitle'},
                newInput({name: 'nouveauDG', label: 'Nouveau DG', type: 'radio',
                    options: [
                        {value: "oui", label: "Oui"},
                        {value: "non", label: "Non"},
                    ]}, '_required', {
                    on: {
                        input: (val) => {
                            for (let i = 21; i < 28; i++) {
                                transformationSarlEnSasOuSasu.schema[3].children[i] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[i]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[i]);
                            }
                        }
                    }
                }),

                newInput({
                    name: "civiliteDirecteurGeneral",
                    label: "Civilité",
                    type: "select",
                    options: [
                        {value: "", label: "Choisissez"},
                        {value: "homme", label: "Homme"},
                        {value: "femme", label: "Femme"},],
                }, '_required', {}, 'hidden'),
                newInput({name: "nomDirecteurGeneral", label: "Nom", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "prenomDirecteurGeneral", label: "Prénom", type: "text"}, '_required', {}, 'hidden'),
                //23
                newInput({name: "adresseDirecteurGeneral", label: "Adresse", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "adresseSuiteDirecteurGeneral", label: "Adresse Suite", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "codePostalDirecteurGeneral", label: "Code postal", type: "text"}, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);
                                transformationSarlEnSasOuSasu.schema[3].children[27].options = data.options;

                                if (data.ville_slug !== null) {
                                    transformationSarlEnSasOuSasu.model.mentionGroup[0].villeDirecteurGeneral = data.ville_slug;
                                }
                            }
                        }
                    }
                }, 'hidden'),
                newInput({
                    name: "villeDirecteurGeneral",
                    label: "ville",
                    type: "select",
                    options: [{value: "", label: "Choisissez"}, {value: "neuilly sur marne", label: "Neuilly"},],
                }, '_required', {}, 'hidden'),
                //27


                {label: 'Commissaires aux comptes', type: 'title', "label-class": 'subtitle'},
                newInput({name: 'nouveauCAC', label: 'Nouveau CAC', type: 'radio',
                    options: [
                        {value: "oui", label: "Oui"},
                        {value: "non", label: "Non"},
                    ]}, '_required', {
                    on: {
                        input: (val) => {
                            if(val === 'oui') {
                                transformationSarlEnSasOuSasu.schema[3].value[0].typeSuppleant = 'aucun';
                                transformationSarlEnSasOuSasu.schema[3].value[0].typePersonne = 'physique';

                                transformationSarlEnSasOuSasu.schema[3].children[30] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[30]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[30]);

                                transformationSarlEnSasOuSasu.schema[3].children[31] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[31]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[31]);
                                transformationSarlEnSasOuSasu.schema[3].children[32] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[32]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[32]);
                                transformationSarlEnSasOuSasu.schema[3].children[33] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[33]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[33]);

                                transformationSarlEnSasOuSasu.schema[3].children[40] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[40]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[40]);
                                transformationSarlEnSasOuSasu.schema[3].children[41] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[41]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[41]);
                                transformationSarlEnSasOuSasu.schema[3].children[42] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[42]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[42]);
                                transformationSarlEnSasOuSasu.schema[3].children[43] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[43]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[43]);

                                transformationSarlEnSasOuSasu.schema[3].children[44] = val === 'oui' ? visible(transformationSarlEnSasOuSasu.schema[3].children[44]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[44]);
                            } else if(val ==='non') {
                                for (let i = 30; i < 58; i++) {
                                    transformationSarlEnSasOuSasu.schema[3].children[i] = hidden(transformationSarlEnSasOuSasu.schema[3].children[i]);
                                }
                                transformationSarlEnSasOuSasu.schema[3].value[0].typePersonne = 'physique';
                                transformationSarlEnSasOuSasu.schema[3].value[0].typeSuppleant = 'aucun';
                            }
                        }
                    }
                    }),
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            transformationSarlEnSasOuSasu.schema[3].children[31] = val === 'physique' || val === '' ? visible(transformationSarlEnSasOuSasu.schema[3].children[31]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[31]);
                            transformationSarlEnSasOuSasu.schema[3].children[32] = val === 'physique' || val === '' ? visible(transformationSarlEnSasOuSasu.schema[3].children[32]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[32]);
                            transformationSarlEnSasOuSasu.schema[3].children[33] = val === 'physique' || val === '' ? visible(transformationSarlEnSasOuSasu.schema[3].children[33]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[33]);

                            transformationSarlEnSasOuSasu.schema[3].children[34] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[34]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[34]);
                            transformationSarlEnSasOuSasu.schema[3].children[35] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[35]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[35]);
                            transformationSarlEnSasOuSasu.schema[3].children[36] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[36]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[36]);
                            transformationSarlEnSasOuSasu.schema[3].children[37] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[37]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[37]);
                            transformationSarlEnSasOuSasu.schema[3].children[38] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[38]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[38]);
                            transformationSarlEnSasOuSasu.schema[3].children[39] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[39]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[39]);
                        }
                    }}, 'hidden'),
                //30
                newInput({
                    name: "civiliteCommissaireCompte",
                    label: "Civilité",
                    type: "select",
                    options: [
                        {value: "", label: "Choisissez"},
                        {value: "homme", label: "Homme"},
                        {value: "femme", label: "Femme"},],
                }, '_required', {}, 'hidden'),
                newInput({name: "nomCommissaireCompte", label: "Nom", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "prenomCommissaireCompte", label: "Prénom", type: "text"}, '_required', {}, 'hidden'),
                //33
                newInput({name: "rcsCommissaireCompte", label: "RCS", type: "text"}, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if (!data.result) {
                                return false;
                            }

                            transformationSarlEnSasOuSasu.model.mentionGroup[0].denominationCommissaireCompte = data.values.societe.societeDenomination;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].lieuImmatriculationCommissaireCompte = data.values.societe.societeRcsVille;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].formesSocietesCommissaireCompte = data.values.societe.societeFormeJuridique;

                            transformationSarlEnSasOuSasu.model.mentionGroup[0].adresseCommissaireCompte = data.values.siegeSocial.societeAdresse;

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            transformationSarlEnSasOuSasu.schema[3].children[43].options = dataCP.options;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].codePostalCommissaireCompte = data.values.siegeSocial.societeCodePostal;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].villeCommissaireCompte = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput({name: "lieuImmatriculationCommissaireCompte", label: "Lieu d'Immatriculation (RCS)", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "denominationCommissaireCompte", label: "Dénomination", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "sigleCommissaireCompte", label: "Sigle", type: "text"}, '_required', {}, 'hidden'),
                newInput({
                    name: "formesSocietesCommissaireCompte",
                    label: "Forme",
                    type: "select",
                    options: [
                        {value: "",         label: "Choisissez"},
                        {value: "sa",       label: "Société anonyme"},
                        {value: "sad",      label: "Société anonyme à directoire"},
                        {value: "saca",     label: "Société anonyme à conseil d'administration"},
                        {value: "saemd",    label: "Société anonyme d'économie mixte à directoire"},
                        {value: "saemcd",   label: "Société anonyme d'économie mixte à conseil d'administration"},
                        {value: "sahd",     label: "Société anonyme de HLM à directoire"},
                        {value: "sahca",    label: "Société anonyme de HLM à conseil d'administration"},
                        {value: "sarl",     label: "Société à responsabilité limité"},
                        {value: "sarlau",   label: "Société à responsabilité limitée à associé unique"},
                        {value: "sarlu",    label: "Société à responsabilité limitée unipersonnelle"},
                        {value: "sas",      label: "Société par action simplifié"},
                        {value: "sasau",    label: "Société par action simplifié à associé unique"},
                        {value: "sc",       label: "Société civile"},
                        {value: "sci",      label: "Société civile immobilière"},
                        {value: "scm",      label: "Société civile de moyens"},
                        {value: "scp",      label: "Société civile professionnelle"},
                        {value: "scea",     label: "Société civile d'exploitation agricole"},
                        {value: "selrl",    label: "Société d'exercice libéral à responsabilité limitée"},
                        {value: "selas",    label: "Société d'exercice libéral par action simplifiée"},
                        {value: "selfa",    label: "Société d'exercice libéral à forme anonyme"},
                        {value: "snc",      label: "Société en nom collectif"},
                        {value: "ste",      label: "Société technique équipement"},
                        {value: "scorl",    label: "Société coopérative ouvrière à responsabilité limitée"},

                    ],
                    values: {
                        "sa":       "Société anonyme",
                        "sad":      "Société anonyme à directoire",
                        "saca":     "Société anonyme à conseil d'administration",
                        "saemd":    "Société anonyme d'économie mixte à directoire",
                        "saemcd":   "Société anonyme d'économie mixte à conseil d'administration",
                        "sahd":     "Société anonyme de HLM à directoire",
                        "sahca":    "Société anonyme de HLM à conseil d'administration",
                        "sarl":     "Société à responsabilité limité",
                        "sarlau":   "Société à responsabilité limitée à associé unique",
                        "sarlu":    "Société à responsabilité limitée unipersonnelle",
                        "sas":      "Société par action simplifié",
                        "sasau":    "Société par action simplifié à associé unique",
                        "sc":       "Société civile",
                        "sci":      "Société civile immobilière",
                        "scm":      "Société civile de moyens",
                        "scp":      "Société civile professionnelle",
                        "scea":     "Société civile d'exploitation agricole",
                        "selrl":    "Société d'exercice libéral à responsabilité limitée",
                        "selas":    "Société d'exercice libéral par action simplifiée",
                        "selfa":    "Société d'exercice libéral à forme anonyme",
                        "snc":      "Société en nom collectif"
                    },
                }, '_required', {}, 'hidden'),
                newInput({name: "montantCapitalCommissaireCompte", label: "Montant du Capital", type: "text", vfeMask: mask.capitalSigned}, '_required', {}, 'hidden'),
                //39
                newInput({name: "adresseCommissaireCompte", label: "Adresse", type: "text"}, '_required',  {}, 'hidden'),
                newInput({name: "adresseSuiteCommissaireCompte", label: "Adresse Suite", type: "text"}, '_required',  {}, 'hidden'),
                newInput({name: "codePostalCommissaireCompte", label: "Code postal", type: "text"}, '_required',  {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);
                                transformationSarlEnSasOuSasu.schema[3].children[43].options = data.options;

                                if (data.ville_slug !== null) {
                                    transformationSarlEnSasOuSasu.model.mentionGroup[0].villeCommissaireCompte = data.ville_slug;
                                }
                            }
                        }
                    }
                }, 'hidden'),
                newInput({
                    name: "villeCommissaireCompte",
                    label: "ville",
                    type: "select",
                    options: [{value: "", label: "Choisissez"}, {value: "neuilly sur marne", label: "Neuilly"},],
                }, '_required',  {}, 'hidden'),
                //43
                newInput(def.typeSuppleant, '_required', {
                    on: {
                        input: (val) => {
                            transformationSarlEnSasOuSasu.schema[3].children[45] = val === 'physique' || val === '' ? visible(transformationSarlEnSasOuSasu.schema[3].children[45]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[45]);
                            transformationSarlEnSasOuSasu.schema[3].children[46] = val === 'physique' || val === '' ? visible(transformationSarlEnSasOuSasu.schema[3].children[46]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[46]);
                            transformationSarlEnSasOuSasu.schema[3].children[47] = val === 'physique' || val === '' ? visible(transformationSarlEnSasOuSasu.schema[3].children[47]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[47]);

                            transformationSarlEnSasOuSasu.schema[3].children[48] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[48]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[48]);
                            transformationSarlEnSasOuSasu.schema[3].children[49] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[49]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[49]);
                            transformationSarlEnSasOuSasu.schema[3].children[50] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[50]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[50]);
                            transformationSarlEnSasOuSasu.schema[3].children[51] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[51]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[51]);
                            transformationSarlEnSasOuSasu.schema[3].children[52] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[52]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[52]);
                            transformationSarlEnSasOuSasu.schema[3].children[53] = val === 'morale' ? visible(transformationSarlEnSasOuSasu.schema[3].children[53]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[53]);

                            transformationSarlEnSasOuSasu.schema[3].children[54] = val === 'aucun' ? hidden(transformationSarlEnSasOuSasu.schema[3].children[54]) : visible(transformationSarlEnSasOuSasu.schema[3].children[54]);
                            transformationSarlEnSasOuSasu.schema[3].children[55] = val === 'aucun' ? hidden(transformationSarlEnSasOuSasu.schema[3].children[55]) : visible(transformationSarlEnSasOuSasu.schema[3].children[55]);
                            transformationSarlEnSasOuSasu.schema[3].children[56] = val === 'aucun' ? hidden(transformationSarlEnSasOuSasu.schema[3].children[56]) : visible(transformationSarlEnSasOuSasu.schema[3].children[56]);
                            transformationSarlEnSasOuSasu.schema[3].children[57] = val === 'aucun' ? hidden(transformationSarlEnSasOuSasu.schema[3].children[57]) : visible(transformationSarlEnSasOuSasu.schema[3].children[57]);

                        }
                    }
                }, 'hidden'),

                newInput({
                    name: "civiliteSuppleant",
                    label: "Civilité",
                    type: "select",
                    options: [
                        {value: "", label: "Choisissez"},
                        {value: "homme", label: "Homme"},
                        {value: "femme", label: "Femme"},],
                }, '_required', {}, 'hidden'),
                newInput({name: "nomSuppleant", label: "Nom", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "prenomSuppleant", label: "Prénom", type: "text"}, '_required', {}, 'hidden'),
                //47
                newInput({name: "rcsSuppleant", label: "RCS", type: "text"}, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if (!data.result) {
                                return false;
                            }

                            transformationSarlEnSasOuSasu.model.mentionGroup[0].denominationSuppleant = data.values.societe.societeDenomination;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].lieuImmatriculationSuppleant = data.values.societe.societeRcsVille;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].formesSocietesSuppleant = data.values.societe.societeFormeJuridique;

                            transformationSarlEnSasOuSasu.model.mentionGroup[0].adresseSuppleant = data.values.siegeSocial.societeAdresse;

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            transformationSarlEnSasOuSasu.schema[3].children[57].options = dataCP.options;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].codePostalSuppleant = data.values.siegeSocial.societeCodePostal;
                            transformationSarlEnSasOuSasu.model.mentionGroup[0].villeSuppleant = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput({name: "lieuImmatriculationSuppleant", label: "Lieu d'Immatriculation (RCS)", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "denominationSuppleant", label: "Dénomination", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "sigleSuppleant", label: "Sigle", type: "text"}, '_required', {}, 'hidden'),
                newInput({
                    name: "formesSocietesSuppleant",
                    label: "Forme",
                    type: "select",
                    options: [
                        {value: "",         label: "Choisissez"},
                        {value: "sa",       label: "Société anonyme"},
                        {value: "sad",      label: "Société anonyme à directoire"},
                        {value: "saca",     label: "Société anonyme à conseil d'administration"},
                        {value: "saemd",    label: "Société anonyme d'économie mixte à directoire"},
                        {value: "saemcd",   label: "Société anonyme d'économie mixte à conseil d'administration"},
                        {value: "sahd",     label: "Société anonyme de HLM à directoire"},
                        {value: "sahca",    label: "Société anonyme de HLM à conseil d'administration"},
                        {value: "sarl",     label: "Société à responsabilité limité"},
                        {value: "sarlau",   label: "Société à responsabilité limitée à associé unique"},
                        {value: "sarlu",    label: "Société à responsabilité limitée unipersonnelle"},
                        {value: "sas",      label: "Société par action simplifié"},
                        {value: "sasau",    label: "Société par action simplifié à associé unique"},
                        {value: "sc",       label: "Société civile"},
                        {value: "sci",      label: "Société civile immobilière"},
                        {value: "scm",      label: "Société civile de moyens"},
                        {value: "scp",      label: "Société civile professionnelle"},
                        {value: "scea",     label: "Société civile d'exploitation agricole"},
                        {value: "selrl",    label: "Société d'exercice libéral à responsabilité limitée"},
                        {value: "selas",    label: "Société d'exercice libéral par action simplifiée"},
                        {value: "selfa",    label: "Société d'exercice libéral à forme anonyme"},
                        {value: "snc",      label: "Société en nom collectif"},
                        {value: "ste",      label: "Société technique équipement"},
                        {value: "scorl",    label: "Société coopérative ouvrière à responsabilité limitée"},

                    ],
                    values: {
                        "sa":       "Société anonyme",
                        "sad":      "Société anonyme à directoire",
                        "saca":     "Société anonyme à conseil d'administration",
                        "saemd":    "Société anonyme d'économie mixte à directoire",
                        "saemcd":   "Société anonyme d'économie mixte à conseil d'administration",
                        "sahd":     "Société anonyme de HLM à directoire",
                        "sahca":    "Société anonyme de HLM à conseil d'administration",
                        "sarl":     "Société à responsabilité limité",
                        "sarlau":   "Société à responsabilité limitée à associé unique",
                        "sarlu":    "Société à responsabilité limitée unipersonnelle",
                        "sas":      "Société par action simplifié",
                        "sasau":    "Société par action simplifié à associé unique",
                        "sc":       "Société civile",
                        "sci":      "Société civile immobilière",
                        "scm":      "Société civile de moyens",
                        "scp":      "Société civile professionnelle",
                        "scea":     "Société civile d'exploitation agricole",
                        "selrl":    "Société d'exercice libéral à responsabilité limitée",
                        "selas":    "Société d'exercice libéral par action simplifiée",
                        "selfa":    "Société d'exercice libéral à forme anonyme",
                        "snc":      "Société en nom collectif"
                    },
                }, '_required', {}, 'hidden'),
                newInput({name: "montantCapitalSuppleant", label: "Montant du Capital", type: "text", vfeMask: mask.capitalSigned}, '_required', {}, 'hidden'),
                //53
                newInput({name: "adresseSuppleant", label: "Adresse", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "adresseSuiteSuppleant", label: "Adresse Suite", type: "text"}, '_required', {}, 'hidden'),
                newInput({name: "codePostalSuppleant", label: "Code postal", type: "text"}, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);
                                transformationSarlEnSasOuSasu.schema[3].children[57].options = data.options;

                                if (data.ville_slug !== null) {
                                    transformationSarlEnSasOuSasu.model.mentionGroup[0].villeSuppleant = data.ville_slug;
                                }
                            }
                        }
                    }
                }, 'hidden'),
                newInput({
                    name: "villeSuppleant",
                    label: "ville",
                    type: "select",
                    options: [{value: "", label: "Choisissez"}, {value: "neuilly sur marne", label: "Neuilly"},],
                }, '_required', {}, 'hidden'),
                //57

                {label: 'Admission aux assemblées', type: 'title', "label-class": 'subtitle'},
                newInput(def.typeAdmission, '_required', {
                    on: {
                        input: (val) => {
                            transformationSarlEnSasOuSasu.schema[3].children[60] = val === 'autre' ? visible(transformationSarlEnSasOuSasu.schema[3].children[60]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[60]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden'),
                //60
                {label: 'Cession et transmission des actions', type: 'title', "label-class": 'subtitle'},
                newInput(def.typeTransmissionAction, '_required', {
                    on: {
                        input: (val) => {
                            transformationSarlEnSasOuSasu.schema[3].children[63] = val === 'autre' ? visible(transformationSarlEnSasOuSasu.schema[3].children[63]) : hidden(transformationSarlEnSasOuSasu.schema[3].children[63]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden')
            ]
        }
    ]
};